<template>
  <form @submit.prevent class="formulario-container" id="cadastro-campanha">
    <div class="formulario-linha linha-tripla">
      <div class="formulario-item medio">
        <label class="formulario-item-label">Segmentação</label>
        <multiselect label="descricao" track-by="descricao" v-model="autorizada" :options="autorizadaSelect"
          :searchable="false" :allow-empty="false" placeholder="" select-label="Selecionar" deselect-label="Remover"
          selected-label="Selecionado" @input="limpaFiltros()" />
      </div>

      <div class="formulario-item medio" v-if="autorizada.id == 1 || autorizada.id == 4">
        <label class="formulario-item-label">Assistência</label>
        <multiselect ref="assistencia" label="descricao" track-by="descricao" v-model="filtros.assistencias"
          :options="assistencias" :searchable="true" :allow-empty="false" placeholder="Selecione a(s) assistência(s)"
          select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado"
          @input="assistenciaSelecionado('assistencia')" />
      </div>

      <div class="formulario-item medio" v-if="autorizada.id == 2 || autorizada.id == 4">
        <label class="formulario-item-label">Franquia</label>
        <multiselect ref="franquia" label="descricao" track-by="descricao" v-model="filtros.franquias"
          :options="franquias" :searchable="true" :allow-empty="false" placeholder="Selecione a(s) franquia(s)"
          select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado"
          @input="assistenciaSelecionado('franquia')" />
      </div>

      <div id="box-filtros-assistencia" v-if="filtrosLista.assistencias.length > 0" class="box-filtros">
        <div class="box-btn-titulo">
          <h2 class="titulo-filtro">Assistência(s) selecionada(s)</h2>
          <p class="btn-limpar-selecao" @click="(filtrosLista.assistencias = []), (filtros.assistencias = [])">
            Limpar Assistências
          </p>
        </div>
        <div v-if="filtrosLista.assistencias.length > 0" class="tabela-informacoes franquias-assistencias-selecionadas">
          <div class="
              box-colunas-assistencia
              tabela-linha tabela-linha-informacoes
              quebra-linha
            ">
            <div v-for="(assistencia, index) in filtrosLista.assistencias" :key="index" class="coluna-nome-assistencia">
              <p>{{ assistencia.descricao }}</p>
              <button @click="
                filtrosLista.assistencias.splice(filtrosLista.assistencias.indexOf(assistencia), 1)
                " class="btn-excluir-assistencia">
                X
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="box-filtros-franquia" v-if="filtrosLista.franquias.length > 0" class="box-filtros">
        <div class="box-btn-titulo">
          <h2 class="titulo-filtro">Franquia(s) selecionada(s)</h2>
          <p class="btn-limpar-selecao" @click="(filtrosLista.franquias = []), (filtros.franquias = [])">
            Limpar Franquias
          </p>
        </div>
        <div v-if="filtrosLista.franquias.length > 0" class="tabela-informacoes franquias-assistencias-selecionadas">
          <div class="
              box-colunas-assistencia
              tabela-linha tabela-linha-informacoes
              quebra-linha
            ">
            <div v-for="(franquia, index) in filtrosLista.franquias" :key="index" class="coluna-nome-assistencia">
              <p>{{ franquia.descricao }}</p>
              <button @click="
                filtrosLista.franquias.splice(
                  filtrosLista.franquias.indexOf(franquia),
                  1
                )
                " class="btn-excluir-assistencia">
                X
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="formulario-item medio">
        <label class="formulario-item-label">Cargo</label>
        <multiselect v-model="form.idsCargos" label="descricao" track-by="id" :options="cargos" :searchable="false"
          :multiple="true" placeholder="Seleciona o(s) cargo(s)" select-label="Selecionar" deselect-label="Remover"
          selected-label="Selecionado" :allow-empty="true" />
      </div>
      <div class="formulario-item medio">
        <label class="formulario-item-label">Título - ({{ 50 - form.titulo.length }} caracteres restantes)</label>
        <input type="text" v-model="form.titulo" maxlength="50" />
      </div>

      <div class="formulario-item medio">
        <label class="formulario-item-label">Descritivo - ({{ 80 - form.descricaoBreve.length }} caracteres
          restantes)</label>
        <input type="text" v-model="form.descricaoBreve" maxlength="80" />
      </div>

      <div class="formulario-item medio">
        <label class="formulario-item-label">Tipo de Campanha</label>
        <multiselect v-model="form.tipoCampanha" label="nome" track-by="nome" :options="tipoCampanha" :searchable="false"
          placeholder="Selecione" select-label="Selecionar" deselect-label=" " selected-label="Selecionado"
          :allow-empty="false" />
      </div>

      <div class="formulario-item medio">
        <label class="formulario-item-label">Ordenação</label>
        <multiselect v-model="form.ordenacao" label="id" track-by="id" :options="ordenacao" :searchable="false"
          placeholder="Selecione" select-label="Selecionar" deselect-label=" " selected-label="Selecionado"
          :allow-empty="false" />
      </div>

      <div class="formulario-item medio">
        <label class="formulario-item-label">Permite Anexo</label>
        <multiselect v-model="form.permiteAnexoUsuario" label="nome" track-by="nome" :options="permiteAnexo"
          :searchable="false" placeholder="Selecione" select-label="Selecionar" deselect-label=" "
          selected-label="Selecionado" :allow-empty="false" />
      </div>
      <div class="formulario-item medio">
        <label class="formulario-item-label">Ativo</label>
        <multiselect v-model="form.ativo" label="nome" track-by="nome" :options="ativo" :searchable="false"
          placeholder="Selecione" select-label="Selecionar" deselect-label=" " selected-label="Selecionado"
          :allow-empty="false" />
      </div>
      <div class="formulario-item medio">
        <label class="formulario-item-label">Data inicio</label>
        <datepicker v-model="form.dataInicio" :language="ptBR" />
      </div>
      <div class="formulario-item medio">
        <label class="formulario-item-label">Data final</label>
        <datepicker v-model="form.dataFim" :language="ptBR" />
      </div>
      <div class="formulario-item medio">
        <label class="formulario-item-label">Data Limite</label>
        <datepicker v-model="form.dataLimite" :language="ptBR" />
      </div>
    </div>

    <div class="formulario-item">
      <label class="formulario-item-label">Descritivo</label>
      <vue-editor id="descritivo" ref="descritivo" v-model="form.descricao" />
    </div>

    <div class="formulario-item container-upload">
      <label class="formulario-item-label"><span v-if="!anexo">{{ nomeAnexoPdf }} </span>
        <a :href="nomeAnexoPdf" target="_blank" v-if="anexo">Ver anexo</a></label>
      <input id="input-pdf" type="file" accept=".pdf" @change="atualizarPdf('pdf', 'arquivoPdf', $event)"
        ref="inputAtualizarPdf" />

      <div class="pdf-flex">
        <label :for="!anexo && !nomeAnexoPdf ? 'input-pdf' : ''" :class="!anexo && !nomeAnexoPdf
          ? 'pdf-btn btn-icon'
          : 'pdf-btn btn-icon desabilitado'
          ">Adicionar regulamento</label>
        <transition name="fade">
          <button :class="form.anexoCampanha || nomeAnexoPdf
            ? 'pdf-btn btn-icon btn-excluir-pdf'
            : 'pdf-btn btn-icon btn-excluir-pdf desabilitado'
            " @click="excluiRegulamento()">
            Excluir regulamento
          </button>
        </transition>
      </div>
    </div>

    <div class="formulario-linha linha-dupla">
      <label class="label-titulo-banner">Banner topo
        <span class="informativo">(ficará visível no topo e dentro da campanha)</span></label>

      <div class="formulario-item container-upload">
        <input id="input-imagem-desktop-banner-topo" type="file" accept=".png, .jpeg, .jpg" @change="
          atualizarImagem('bnTopoDesktop', 'bannerTopoDesktop', $event)
          " />
        <div :style="`background-image: url(${bannerTopoDesktop});`" :class="bannerTopoDesktop
          ? 'bg-upload-div upload-div active'
          : 'bg-upload-div upload-div'
          ">
          <span v-if="bannerTopoDesktop == null">Desktop - 1200px x 300px</span>
        </div>
        <div>
          <label for="input-imagem-desktop-banner-topo" class="upload-btn">Adicionar Imagem</label>
          <transition name="fade">
            <a v-if="bannerTopoDesktop" class="remove-image" @click="
              removerImagem(
                'bnTopoDesktop',
                'bannerTopoDesktop',
                'input-imagem-desktop-banner-topo'
              )
              ">
              Remover
            </a>
          </transition>
        </div>
      </div>

      <div class="formulario-item container-upload">
        <input id="input-imagem-mobile-banner-topo" type="file" accept=".png, .jpeg, .jpg"
          @change="atualizarImagem('bnTopoMobile', 'bannerTopoMobile', $event)" />
        <div :style="`background-image: url(${bannerTopoMobile});`" :class="bannerTopoMobile
          ? 'bg-upload-div upload-div active'
          : 'bg-upload-div upload-div'
          ">
          <span v-if="bannerTopoMobile == null">Mobile - 375px x 375px</span>
        </div>
        <div>
          <label for="input-imagem-mobile-banner-topo" class="upload-btn">Adicionar Imagem</label>
          <transition name="fade">
            <a v-if="bannerTopoMobile" class="remove-image" @click="
              removerImagem(
                'bnTopoMobile',
                'bannerTopoMobile',
                'input-imagem-mobile-banner-topo'
              )
              ">
              Remover
            </a>
          </transition>
        </div>
      </div>
    </div>

    <div class="formulario-linha linha-dupla">
      <label class="label-titulo-banner">Banner Home e Campanha
        <span class="informativo">(ficará visível na página inicial e nas campanhas)</span></label>

      <div class="formulario-item container-upload">
        <input id="input-imagem-desktop-mini-banner" type="file" accept=".png, .jpeg, .jpg"
          @change="atualizarImagem('miniBanner', 'miniBanner', $event)" />
        <div :style="`background-image: url(${miniBanner});`" :class="miniBanner
          ? 'bg-upload-div upload-div active'
          : 'bg-upload-div upload-div'
          ">
          <span v-if="miniBanner == null">280px x 280px</span>
        </div>
        <div>
          <label for="input-imagem-desktop-mini-banner" class="upload-btn">Adicionar Imagem</label>
          <transition name="fade">
            <a v-if="miniBanner" class="remove-image" @click="
              removerImagem(
                'miniBanner',
                'miniBanner',
                'input-imagem-desktop-mini-banner'
              )
              ">
              Remover
            </a>
          </transition>
        </div>
      </div>
    </div>
    <div class="container-btns">
      <button class="btn btn-cancelar" @click.prevent="$router.push('/cadastro-campanhas/listar')">
        Voltar
      </button>
      <button class="btn btn-laranja" @click="salvar">Salvar</button>
    </div>
  </form>
</template>

<script>
import { listarAssistencias, listarRegioes, listaCargoPorAutorizada } from "@/services/autorizadas";
import {
  atualizarCampanha,
  cadastrarCampanha,
  detalhesCampanha,
} from "@/services/campanhas";
import { listarCargos } from "@/services/espelho";
import AwesomeMask from "awesome-mask";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import { required, minLength } from "vuelidate/lib/validators";
import moment from "moment";
import { VueEditor } from "vue2-editor";

export default {
  name: "FormularioBannerTreinamento",
  props: ["editar"],
  components: {
    Multiselect,
    Datepicker,
    VueEditor,
  },
  directives: {
    mask: AwesomeMask,
  },
  data() {
    return {
      ptBR: ptBR,
      bannerTopoDesktop: null,
      bannerTopoMobile: null,
      miniBanner: null,
      imgPremio: null,
      anexoCampanha: null,
      nomeAnexoPdf: "",
      autorizada: [],
      assistenciaSelecionados: [],
      assistencias: [],
      franquias: [],
      cargos: [],
      regiao: [],
      anexo: false,
      temAnexo: false,
      autorizadaSelect: [
        {
          id: 1,
          descricao: "Assistência",
        },
        {
          id: 2,
          descricao: "Franquia",
        },
        {
          id: 4,
          descricao: "Assistência/Franquia",
        },
      ],
      permiteAnexo: [
        { id: true, nome: "SIM" },
        { id: false, nome: "NÃO" },
      ],
      ativo: [
        { id: true, nome: "SIM" },
        { id: false, nome: "NÃO" },
      ],
      tipoCampanha: [
        { id: 1, nome: "Sazonal" },
        { id: 2, nome: "Aniversário" },
        { id: 3, nome: "Anual" },
      ],
      ordenacao: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
      tipo: [],
      extensoes: ["png", "jpg", "jpeg", "gif", "pdf"],
      filtros: [{
        assistencias: [],
        franquias: [],
        regioes: []
      }],
      filtrosLista: {
        assistencias: [],
        franquias: [],
        regioes: []
      },
      form: {
        dataFim: new Date().setDate(new Date().getDate() + 30),
        dataInicio: new Date(),
        dataLimite: new Date().setDate(new Date().getDate() + 29),
        titulo: "",
        subtitulo: "",
        descricao: "",
        descricaoBreve: "",
        idsRegioes: [],
        ativo: [],
        ordenacao: [],
        tipoCampanha: [],
        permiteAnexoUsuario: [],
        idsAutorizadas: [],
        idsSegmentacao: [],
        idsCargos: [],
        numeroPontos: [],
        bnTopoDesktop: null,
        bnTopoMobile: null,
        miniBanner: null,
        imgPremio: null,
        anexoCampanha: null,
        excluirRegulamento: false
      },
      dadosEditar: {},
      selectTodosAssistencia: [],
      selectTodosFranquia: [],
    };
  },
  validations: {
    form: {
      nome: { required, minLength: minLength(5) },
      cliente: { required },
      ean: { required },
    },
  },
  watch: {
    autorizada(){
      this.buscarCargos();
    }
  },
  mounted() {
    this.form.ativo = this.ativo.find((item) => item.id == true);
    this.autorizada = this.autorizadaSelect.find((item) => item.id == 1);
    this.form.tipoCampanha = this.tipoCampanha.find((item) => item.id == 1);
    this.form.ordenacao = this.ordenacao.find((item) => item.id == 1);
    this.form.permiteAnexoUsuario = this.permiteAnexo.find(
      (item) => item.id == true
    );
    this.listarAssistencia();
    this.listarRegioes();
    this.buscarCargos();
    if (this.editar) {
      this.buscaDetalhesCampanha();
    }
  },
  methods: {
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    buscaDetalhesCampanha() {
      let id = this.$route.params.id;
      detalhesCampanha(id).then((resp) => {
        const infoDetalhesCampanha = JSON.parse(JSON.stringify(resp.data));
        this.dadosEditar = infoDetalhesCampanha;

        this.autorizada = this.autorizadaSelect.find(
          (item) => item.id == this.dadosEditar.campanha.segmentacao.id
        );

        //Faz a pesquisa e seleciona as assistências
        if (this.autorizada.id == 1 || this.autorizada.id == 4) {
          this.dadosEditar.segmentacao.forEach((element) => {
            this.assistencias.find((item) => {
              if (item.id == element) {
                this.filtrosLista.assistencias.push(item);
              }
            });
          });
          this.filtros.assistencias = this.filtrosLista.assistencias[0]
        }

        //Faz a pesquisa e seleciona as franquias
        if (this.autorizada.id == 2 || this.autorizada.id == 4) {
          this.dadosEditar.segmentacao.forEach((element) => {
            this.franquias.find((item) => {
              if (item.id == element) {
                this.filtrosLista.franquias.push(item);
              }
            });
          });
          this.filtros.franquias = this.filtrosLista.franquias[0]
        }

        if (this.autorizada.id == 2) {
          this.dadosEditar.segmentacao.forEach((element) => {
            let reg = this.regiao.find((item) => item.id == element);
            this.form.idsRegioes.push(reg);
          });
        }

        this.dadosEditar.cargos.forEach((el) => {
          let cargo = this.cargos.find((item) => {
            return item.id == el;
          });
          this.form.idsCargos.push(cargo);
        });

        this.form.titulo = this.dadosEditar.campanha.titulo;
        this.form.subtitulo = this.dadosEditar.campanha.subtitulo;
        this.form.tipoCampanha = this.tipoCampanha.find(
          (item) => item.id == this.dadosEditar.campanha.tipoCampanha.id
        );
        this.form.ordenacao = this.ordenacao.find(
          (item) => item.id == this.dadosEditar.campanha.ordem
        );
        this.form.permiteAnexoUsuario = this.permiteAnexo.find(
          (item) => item.id == this.dadosEditar.campanha.permiteAnexoUsuario
        );
        this.form.ativo = this.ativo.find(
          (item) => item.id == this.dadosEditar.campanha.ativo
        );
        this.form.dataInicio = moment(
          this.dadosEditar.campanha.dataInicio
        ).format("MM-DD-YYYY");
        this.form.dataFim = moment(this.dadosEditar.campanha.dataFim).format(
          "MM-DD-YYYY"
        );

        this.form.dataLimite = moment(
          this.dadosEditar.campanha.dataLimite
        ).format("MM-DD-YYYY");
        this.form.descricao = this.dadosEditar.campanha.descricao;
        this.form.descricaoBreve = this.dadosEditar.campanha.descricaoBreve;

        //Verifica se tem anexo ou apenas o caminho vazio
        if (this.dadosEditar.campanha.anexoInformativo != null) {
          let extensaoAnexo = this.dadosEditar.campanha.anexoInformativo
            .split(".")
            .pop();

          this.extensoes.forEach((item) => {
            if (extensaoAnexo == item) {
              this.nomeAnexoPdf = this.dadosEditar.campanha.anexoInformativo;
              this.anexo = true;
              this.temAnexo = true;
            }
          });
        }


        this.bannerTopoDesktop =
          "data:image/png;base64," + this.dadosEditar.campanha.bannerDesktop;
        this.form.bnTopoDesktop = this.dadosEditar.campanha.bannerDesktop;

        this.bannerTopoMobile =
          "data:image/png;base64," + this.dadosEditar.campanha.bannerMobile;
        this.form.bnTopoMobile = this.dadosEditar.campanha.bannerMobile;

        this.miniBanner =
          "data:image/png;base64," +
          this.dadosEditar.campanha.imagemGradeDesktop;
        this.form.miniBanner = this.dadosEditar.campanha.imagemGradeDesktop;

        this.imgPremio = this.dadosEditar.campanha.imagemGradeDesktop;
      });
    },
    listarAssistencia() {
      listarAssistencias(1).then((resp) => {
        this.selectTodosAssistencia = resp.data.map((item) => item.id);
        this.assistencias = resp.data;
        this.assistencias.unshift({ id: "assistencia", descricao: "Todos" });
      });
      listarAssistencias(2).then((resp) => {
        this.selectTodosFranquia = resp.data.map((item) => item.id);
        this.franquias = resp.data;
        this.franquias.unshift({ id: "franquia", descricao: "Todos" });
      });
    },
    listarRegioes() {
      listarRegioes().then((resp) => {
        this.regiao = resp.data;
      });
    },
    buscarCargos() {
      if (this.autorizada.id && this.autorizada.id != 4) {
        listaCargoPorAutorizada(this.autorizada.id).then(resp => {
          this.cargos = resp.data;
        })
        return;
      }

      listarCargos().then((resp) => {
        this.cargos = resp.data;
      });
    },
    salvar() {
      const data = new FormData();
      data.append("IdTipoCampanha", this.form.tipoCampanha.id);
      data.append("IdSegmentacao", this.autorizada.id);
      data.append("Ordem", this.form.ordenacao.id);

      data.append("Titulo", this.form.titulo);
      data.append("Subtitulo", this.form.descricaoBreve);
      data.append("DescricaoBreve", this.form.descricaoBreve);
      data.append("Descricao", this.form.descricao);
      data.append("PermiteAnexoUsuario", this.form.permiteAnexoUsuario.id);
      data.append("Ativo", this.form.ativo.id);

      data.append("DataInicio", this.formatarData(this.form.dataInicio) + " 00:01:00");
      data.append("DataLimite", this.formatarData(this.form.dataLimite) + " 23:59:00");
      data.append("DataFim", this.formatarData(this.form.dataFim) + " 23:59:00");
      this.form.idsRegioes.map((item) => { data.append("IdsRegioes", item.id); });
      this.form.idsCargos.map((item) => { data.append("IdsCargos", item.id); });

      //Separa ids de franquias e assistências e junta em um único objeto
      let assistenciasIds = [];
      let franquiasIds = [];

      if (this.filtrosLista.assistencias.length > 0 && this.filtrosLista.assistencias[0].id == "assistencia") {
        this.assistencias.map((item) => {
          assistenciasIds.push(item.id)
        });
        assistenciasIds.shift()
      }

      if (this.filtrosLista.franquias.length > 0 && this.filtrosLista.franquias[0].id == "franquia") {
        this.franquias.map((item) => {
          franquiasIds.push(item.id)
        });
        franquiasIds.shift()
      }

      if (this.filtrosLista.assistencias.length > 0 && this.filtrosLista.assistencias[0].id != "assistencia") {
        this.filtrosLista.assistencias.map((item) => {
          franquiasIds.push(item.id)
        });
      }

      if (this.filtrosLista.franquias.length > 0 && this.filtrosLista.franquias[0].id != "franquia") {
        this.filtrosLista.franquias.map((item) => {
          assistenciasIds.push(item.id)
        });
      }

      let juntaAssisFranq = [...assistenciasIds, ...franquiasIds]
      // console.log(juntaAssisFranq)
      juntaAssisFranq.map((item) => {
        data.append("IdsAutorizadas", item)
      })
      // data.append("IdsAutorizadas", juntaAssisFranq);

      if (this.editar) {

        if (this.form.excluirRegulamento) {
          data.append("DeletarAnexoInformativo", this.form.excluirRegulamento);
          data.append("AnexoInformativo", null);
        }

        if (this.form.anexoCampanha) {
          data.append("AnexoInformativo", this.form.anexoCampanha);
        }

        if (typeof this.form.miniBanner == "string") {
          var miniBanner = new File(
            [this.b64toBlob(this.form.miniBanner)],
            "temp.jpeg",
            { type: "image/jpeg" }
          );
          data.append("ImagemGradeDesktop", miniBanner);
          data.append("ImagemGradeMobile", miniBanner);
        } else {
          data.append("ImagemGradeDesktop", this.form.miniBanner);
          data.append("ImagemGradeMobile", this.form.miniBanner);
        }

        if (typeof this.form.bnTopoDesktop == "string") {
          var bannerDesktop = new File(
            [this.b64toBlob(this.form.bnTopoDesktop)],
            "temp.jpeg",
            { type: "image/jpeg" }
          );
          data.append("BannerDesktop", bannerDesktop);
        } else {
          data.append("BannerDesktop", this.form.bnTopoDesktop);
        }

        if (typeof this.form.bnTopoMobile == "string") {
          var bannerMobile = new File(
            [this.b64toBlob(this.form.bnTopoMobile)],
            "temp.jpeg",
            { type: "image/jpeg" }
          );
          data.append("BannerMobile", bannerMobile);
        } else {
          data.append("BannerMobile", this.form.bnTopoMobile);
        }

        data.append("IdCampanha", Number(this.$route.params.id));

        atualizarCampanha(data).then((resp) => {
          if (resp.data.sucesso) {
            this.$store.commit("SET_MODAL", {
              ativado: true,
              mensagem: resp.data.mensagem,
              tipo: "sucesso",
            });
            setTimeout(() => {
              this.$store.commit("SET_MODAL", {
                ativado: false,
                mensagem: "",
                tipo: "",
              });
            }, 5000);
            this.$router.push("/cadastro-campanhas/listar");
          }
        });
      } else {
        data.append("ImagemGradeDesktop", this.form.miniBanner);
        data.append("ImagemGradeMobile", this.form.miniBanner);
        data.append("BannerDesktop", this.form.bnTopoDesktop);
        data.append("BannerMobile", this.form.bnTopoMobile);
        data.append("AnexoInformativo", this.form.anexoCampanha);
        cadastrarCampanha(data).then((resp) => {
          if (resp.data.sucesso) {
            this.$store.commit("SET_MODAL", {
              ativado: true,
              mensagem: resp.data.mensagem,
              tipo: "sucesso",
            });
            setTimeout(() => {
              this.$store.commit("SET_MODAL", {
                ativado: false,
                mensagem: "",
                tipo: "",
              });
            }, 2000);
            this.$router.push("/cadastro-campanhas/listar");
          }
        });
      }
    },
    assistenciaSelecionado(segmentacao) {
      if (segmentacao == 'assistencia') {
        if (this.filtros.assistencias.descricao == 'Todos') {
          this.filtrosLista.assistencias = [];
          this.filtrosLista.assistencias.push(this.filtros.assistencias)
        } else {
          this.filtrosLista.assistencias = this.filtrosLista.assistencias.filter((item) => item.descricao != "Todos")
          this.filtrosLista.assistencias.push(this.filtros.assistencias);
        }
      }

      if (segmentacao == 'franquia') {
        if (this.filtros.franquias.descricao == 'Todos') {
          this.filtrosLista.franquias = [];
          this.filtrosLista.franquias.push(this.filtros.franquias)
        } else {
          this.filtrosLista.franquias = this.filtrosLista.franquias.filter((item) => item.descricao != "Todos")
          this.filtrosLista.franquias.push(this.filtros.franquias);
        }
      }
    },
    limpaFiltros() {
      this.filtrosLista.assistencias = [];
      this.filtrosLista.franquias = [];
      this.filtros.assistencias = [];
      this.filtros.franquias = [];
    },
    excluiRegulamento() {
      this.form.excluirRegulamento = true;
      this.form.anexoCampanha = '';
      this.nomeAnexoPdf = null;
      this.anexo = false;
      this.$refs.inputAtualizarPdf.value = ''
    },
    atualizarPdf(form, pdf, $event) {
      this[pdf] = '';
      if (!$event.target.files[0]) {
        this[pdf] = null;
        return false;
      }

      // this.editar = false;
      const reader = new FileReader();
      reader.onload = (bg) => {
        this[pdf] = bg.target.result;
      };

      this.form.anexoCampanha = $event.target.files[0];
      reader.readAsDataURL($event.target.files[0]);
      this.nomeAnexoPdf = $event.target.files[0].name;
    },
    removerImagem(form, imagem, elem) {
      this[imagem] = null;
      this.form[form] = null;
      document.querySelector(`#${elem}`).value = null;
    },
    atualizarImagem(form, imagem, $event) {
      if (!$event.target.files[0]) {
        this[imagem] = null;
        this.form[form] = null;
        return false;
      }
      this.form[form] = $event.target.files[0];
      const reader = new FileReader();
      reader.onload = (bg) => {
        this[imagem] = bg.target.result;
      };
      reader.readAsDataURL($event.target.files[0]);
    },
    formatarData(data) {
      return moment(data).format("YYYY-MM-DD");
    },
  },
};
</script>



<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss">
.btn-excluir-pdf {
  background: #e33434 !important;
  border: none;
  margin-left: 10px !important;
}

#descritivo-premio,
#descritivo {
  height: 300px;
  border-radius: 0 0 5px 5px;
}

#cadastro-campanha {
  .premios {
    margin: 20px 0;
    padding: 20px 0;
  }

  .premio {
    margin-bottom: 50px;
  }

  .label-titulo-banner {
    font-size: 24px;
    width: 100%;
    margin: 10px;
  }

  .bg-upload-div {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .pdf-flex {
    display: flex;
    align-items: center;
  }

  .pdf-btn {
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 5px 0 10px 0;
    cursor: pointer;
    transition: 0.3s;
    color: #fff;
    border-radius: 8px;
    background: #33b933;
    padding: 6px 20px;

    &.desabilitado {
      background: #ccc !important;
      color: #666;
      cursor: auto;
    }
  }

  .maximo-caracteres {
    font-style: italic;
    font-size: 14px;
  }

  .informativo {
    font-size: 16px;
    margin-left: 10px;
    font-style: italic;
  }
}
</style>
