<template>
	<div>
		<div class="produto-editar">
			<div class="conteudo-interno">
				<div class="nova-comunicacao-container">
					<h1>Editar Campanha</h1>
					<transition name="fade">
						<formulario-cadastro-campanhas
							:editar="true"
						/>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FormularioCadastroCampanhas from "@/components/elementos/paginas/FormularioCadastroCampanhas";
export default {
	name: "CadastroCampanhasEditar",
	components: {
		FormularioCadastroCampanhas
	}
};
</script>

<style lang="scss">
.produto-editar {
	position: relative;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;
}
</style>
